// Utils
import { getCookie } from "../cookies";
import metrics from "../metrics";
import { getPropertiesForPlanId } from "./helpers";
import { getProductPromotionAttributes } from "./promotion";

// Store
import cartProductSelectors from "../../store/cart-product/selectors";
import cartSelectors from "../../store/cart/selectors";
import { getStore } from "../../store/createStore";
import { productOfferForId } from "../../store/product-offer/selectors";
import promotionSelectors from "../../store/promotion/selectors";
import { getProductOfferAttributes } from "../planToProduct";

export function trackOrderCompleted(orderNumber, cart, cartProducts) {
  const properties = {
    order_id: orderNumber,
    total: cart.total / 100,
    revenue: (cart.total - cart.total_tax) / 100,
    subtotal: cart.subtotal / 100,
    currency: cart.currency.toUpperCase(),
    tax: cart.total_tax / 100,
    discount: cart.discount_amount / 100,
    coupon: cart.discount_code,
    shipping: cart.shipping_amount / 100,
  };

  const productProperties = cartProducts.map((product) => {
    const { quantity, planId, productOfferId } = product;
    if (planId) {
      return getPropertiesForPlanId(planId, quantity);
    } else if (productOfferId) {
      return getProductOfferAttributes(productOfferId, quantity);
    }
    return {};
  });
  properties.products = productProperties.flat();

  const iterableCampaignId = getCookie("iterableEmailCampaignId");
  if (iterableCampaignId) {
    properties.campaignId = Number(iterableCampaignId);
    properties.templateId = Number(getCookie("iterableTemplateId"));
    properties.messageId = getCookie("iterableMessageId");
  }

  if (getCookie("tiktokEventId")) {
    properties.ttei = new Date().getTime() + "" + Math.random();
  }

  if (getCookie("facebookEventId")) {
    properties.fbp = getCookie("_fbp");
    properties.fbc = getCookie("_fbc");
  }

  metrics.track("Order Completed", properties, {
    addEmail: true,
  });
}

export function trackSubscribe(subscription_id, cart, cartProducts) {
  const properties = {
    subscription_id: subscription_id,
    currency: cart.currency.toUpperCase(),
  };

  // recurring product total from cartProducts where billingType === "recurring"
  const productProperties = cartProducts.map((product) => {
    const { quantity, planId, productOfferId } = product;
    if (planId) {
      return getPropertiesForPlanId(planId, quantity);
    } else if (productOfferId) {
      const productOffer = productOfferForId(
        getStore().getState(),
        productOfferId,
      );
      return productOffer
        .initialPlanIds(getStore().getState())
        .map((planId) => {
          const properties = {
            ...getPropertiesForPlanId(planId, quantity),
            product_offer_id: productOfferId,
            product_offer_name: productOffer.name,
          };
          return properties;
        });
    }
    return {};
  });

  const recurringProductTotal = productProperties
    .filter((product) => product.billing_type === "recurring")
    .reduce((total, product) => total + product.price * product.quantity, 0);

  properties.value = recurringProductTotal;

  if (getCookie("facebookEventId")) {
    properties.fbp = getCookie("_fbp");
    properties.fbc = getCookie("_fbc");
  }

  metrics.track("Subscribe", properties, {
    addEmail: true,
  });
}

export function trackProductAdded(planId, additionalProperties) {
  const state = getStore().getState();

  const { code } = state.pendingCode;

  const productProperties = getPropertiesForPlanId(planId);

  let products = [];
  if (Object.keys(productProperties).length > 0) {
    products = [{ ...productProperties }];
    products[0].id = products[0].product_id;
    delete products[0].product_id;
  }

  let properties = {
    cart_id: _getCartId(state),
    products,
    coupon: code,
  };

  properties = { ...properties, ...productProperties, ...additionalProperties };

  metrics.track("Product Added", properties, {
    addTraitsToContext: true,
    addEmail: true,
    addFacebookCookies: true,
    addTikTokEventId: true,
  });
}

export function trackBundleAdded(productOfferId, additionalProperties) {
  const state = getStore().getState();

  const { code } = state.pendingCode;

  const bundleProperties = getProductOfferAttributes(productOfferId);

  let properties = {
    cart_id: _getCartId(state),
    coupon: code,
  };

  properties = {
    ...properties,
    ...bundleProperties,
    ...additionalProperties,
  };

  metrics.track("Product Added", properties, {
    addTraitsToContext: true,
    addEmail: true,
    addFacebookCookies: true,
    addTikTokEventId: true,
  });
}

export function trackProductRemoved(planId, additionalProperties) {
  const state = getStore().getState();

  const { code } = state.pendingCode;

  let properties = {
    cart_id: _getCartId(state),
    coupon: code,
  };

  const productProperties = getPropertiesForPlanId(planId);
  properties = { ...properties, ...productProperties, ...additionalProperties };

  metrics.track("Product Removed", properties, {
    addTraitsToContext: true,
    addEmail: true,
    addFacebookCookies: true,
  });
}

export function trackBundleRemoved(productOfferId, additionalProperties) {
  const state = getStore().getState();

  const { code } = state.pendingCode;

  let properties = {
    cart_id: _getCartId(state),
    coupon: code,
  };

  const bundleProperties = getProductOfferAttributes(productOfferId);
  properties = { ...properties, ...bundleProperties, ...additionalProperties };

  metrics.track("Product Removed", properties, {
    addTraitsToContext: true,
    addEmail: true,
    addFacebookCookies: true,
  });
}

// Cart Events Only

export function trackCheckoutCTAClicked() {
  const properties = _getCartTrackingProperties();
  return metrics.track("Checkout CTA Clicked", properties);
}

export function trackCartViewed(eventName = "Cart Viewed") {
  const properties = _getCartTrackingProperties();
  metrics.track(eventName, properties, {
    addEmail: true,
  });
}

export function trackCartUpdated() {
  const properties = _getCartTrackingProperties();
  metrics.track("Cart Updated", properties, { cartTraits: true });
}

export function trackCheckoutStarted() {
  const properties = _getCartTrackingProperties();
  metrics.track("Checkout Started", properties, {
    addEmail: true,
  });
}

// Helpers

export function _getCartProducts(state) {
  return cartProductSelectors
    .activeCartProducts(state)
    .flatMap((cartProduct) => {
      const { quantity, planId, productOfferId } = cartProduct;

      if (productOfferId) {
        const productOffer = productOfferForId(state, productOfferId);
        return [getProductOfferAttributes(productOffer.id, quantity)];
      }

      return [getPropertiesForPlanId(planId, quantity)];
    });
}

function _getCartId(state) {
  const activeCart = cartSelectors.activeCart(state);
  return activeCart ? activeCart.id : null;
}

function _getCartTrackingProperties() {
  const state = getStore().getState();
  const productQuantity = cartProductSelectors.activeCartProductQuantity(state);
  const cart = cartSelectors.activeCart(state);

  let promoProperties = {};
  const appliedProductPromotionProduct =
    promotionSelectors.appliedProductPromotionProduct(state);
  if (appliedProductPromotionProduct) {
    promoProperties["product_promotion"] = getProductPromotionAttributes(
      appliedProductPromotionProduct.sku,
    );
  }

  let cartProperties = {};
  if (cart) {
    cartProperties = {
      total: cart.total / 100,
      revenue: (cart.total - cart.totalTax - cart.shippingAmount) / 100,
      subtotal: cart.subtotal / 100,
      currency: cart.currency.toUpperCase(),
      tax: cart.totalTax / 100,
      discount: cart.discountAmount / 100,
      shipping: cart.shippingAmount / 100,
      coupon: cart.discountCode,
    };
  }

  return {
    cart_id: _getCartId(state),
    products: _getCartProducts(state),
    coupon: "",
    num_products: productQuantity,
    ...cartProperties,
    ...promoProperties,
  };
}
