// PressLogos
import Byrdie from "../../static/svg/byrdie.svg";
import CNBC from "../../static/svg/cnbc.svg";
import Cnn from "../../static/svg/cnn.svg";
import Forbes from "../../static/svg/forbes.svg";
import Health from "../../static/svg/health.svg";
import NewYorkTimes from "../../static/svg/nyt.svg";
import Popsugar from "../../static/svg/popsugar.svg";
import Shape from "../../static/svg/shape.svg";
import Vogue from "../../static/svg/vogue.svg";
import Wired from "../../static/svg/wired.svg";

import {
  default as Essentialforwomen,
  default as Essentialforwomen50,
} from "../../static/svg/sticker-essential-for-women.svg";
import Essentialprenatal from "../../static/svg/sticker-essential-prenatal.svg";
import Feeling100 from "../../static/svg/sticker-feeling-100.svg";
import Healthyish from "../../static/svg/sticker-healthyish.svg";
import Labelreader from "../../static/svg/sticker-label-reader.svg";
import Obsessed from "../../static/svg/sticker-obsessed.svg";
import Skeptic from "../../static/svg/sticker-skeptic.svg";
import Trophy from "../../static/svg/sticker-trophy.svg";

import Amex from "../../static/svg/icon-payment-amex.svg";
import ApplePay from "../../static/svg/icon-payment-apple-pay.svg";
import Discover from "../../static/svg/icon-payment-discover.svg";
import GooglePay from "../../static/svg/icon-payment-google-pay.svg";
import MasterCard from "../../static/svg/icon-payment-mastercard.svg";
import PayPal from "../../static/svg/icon-payment-pay-pal.svg";
import Visa from "../../static/svg/icon-payment-visa.svg";

import Aging from "../../static/svg/icon-aging.svg";
import Amino from "../../static/svg/icon-amino.svg";
import Antioxidant from "../../static/svg/icon-antioxidant.svg";
import Blood from "../../static/svg/icon-blood.svg";
import Bone from "../../static/svg/icon-bone.svg";
import Brainsupport from "../../static/svg/icon-brain-support.svg";
import Brain from "../../static/svg/icon-brain.svg";
import Bullethollow from "../../static/svg/icon-bullet-hollow.svg";
import Calmness from "../../static/svg/icon-calmness.svg";
import Digestion from "../../static/svg/icon-digestion.svg";
import Dna from "../../static/svg/icon-dna.svg";
import Energy from "../../static/svg/icon-energy.svg";
import Gene from "../../static/svg/icon-gene.svg";
import Glowing from "../../static/svg/icon-glowing.svg";
import Gutbarriers from "../../static/svg/icon-gut-barriers.svg";
import Guthealth from "../../static/svg/icon-gut-health.svg";
import Heart from "../../static/svg/icon-heart.svg";
import Hormone from "../../static/svg/icon-hormone.svg";
import Immune from "../../static/svg/icon-immune.svg";
import Joint from "../../static/svg/icon-joint.svg";
import Mood from "../../static/svg/icon-mood.svg";
import Musclelean from "../../static/svg/icon-muscle-lean.svg";
import Musclemaintenance from "../../static/svg/icon-muscle-maintenance.svg";
import Muscle from "../../static/svg/icon-muscle.svg";
import Nerve from "../../static/svg/icon-nerve.svg";
import Nervoussystem from "../../static/svg/icon-nervous-system.svg";
import Skinhydration from "../../static/svg/icon-skin-hydration.svg";
import Skin from "../../static/svg/icon-skin.svg";
import Sleep from "../../static/svg/icon-sleep.svg";
import Smooth from "../../static/svg/icon-smooth-skin.svg";
import Thyroid from "../../static/svg/icon-thyroid.svg";
import Vision from "../../static/svg/icon-vision.svg";
import Wrinkles from "../../static/svg/icon-wrinkles.svg";

export { default as BBBLogo } from "../../static/svg/BBB-Logo.svg";
export { default as BCorpLogo } from "../../static/svg/b-corp-logo.svg";
export { default as EssenceVanillaWhite } from "../../static/svg/essence-vanilla-white.svg";
export { default as EssenceVanilla } from "../../static/svg/essence-vanilla.svg";
export { default as FlavorCitrusBerry } from "../../static/svg/flavor-citrus-berry.svg";
export { default as FlavorCitrus } from "../../static/svg/flavor-citrus.svg";
export { default as FlavorMint } from "../../static/svg/flavor-mint.svg";
export { default as FlavorOrange } from "../../static/svg/flavor-orange.svg";
export { default as FlavorVanilla } from "../../static/svg/flavor-vanilla.svg";
export { default as IconHighFiveWhite } from "../../static/svg/icon-high-five-white.svg";
export { default as IconHighFive } from "../../static/svg/icon-high-five.svg";
export { default as IconPlusNoBackground } from "../../static/svg/icon-plus-no-bg.svg";
export { default as IconPlus } from "../../static/svg/icon-plus.svg";
export { default as IconMinus } from "../../static/svg/icon-minus.svg";
export { default as IconStudy } from "../../static/svg/icon-study.svg";
export { default as IconArrowRight } from "../../static/svg/icon-arrow-right-16x16.svg";
export { default as AppleLogo } from "../../static/svg/logo-apple.svg";
export { default as GoogleLogo } from "../../static/svg/logo-google.svg";
export { default as PoliciesBadge } from "../../static/svg/policies-badge.svg";
export { default as IconCaretRightWhite } from "../../static/svg/round-caret-r-white.svg";
export { default as IconCaretRight } from "../../static/svg/round-caret-r.svg";

export const PressLogos = {
  nyt: NewYorkTimes,
  wired: Wired,
  vogue: Vogue,
  cnn: Cnn,
  shape: Shape,
  health: Health,
  forbes: Forbes,
  byrdie: Byrdie,
  popsugar: Popsugar,
  cnbc: CNBC,
};

export const Stickers = {
  Healthyish,
  Labelreader,
  Obsessed,
  Essentialforwomen,
  Essentialforwomen50,
  Essentialprenatal,
  Feeling100,
  Skeptic,
  Trophy,
};

export const PaymentIcons = {
  ApplePay,
  GooglePay,
  PayPal,
  Visa,
  MasterCard,
  Amex,
  Discover,
};

export const BenefitsIcons = {
  Skin,
  Bone,
  Brainsupport,
  Heart,
  Immune,
  Brain,
  Nerve,
  Energy,
  Joint,
  Hormone,
  Antioxidant,
  Aging,
  Mood,
  Calmness,
  Dna,
  Blood,
  Gene,
  Glowing,
  Gutbarriers,
  Guthealth,
  Digestion,
  Thyroid,
  Nervoussystem,
  Muscle,
  Musclemaintenance,
  Musclelean,
  Amino,
  Vision,
  Wrinkles,
  Skinhydration,
  Smooth,
  Sleep,
  Bullethollow,
};
