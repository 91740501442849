import Model from "../model";
import { NormalizedModel } from "../utils/normalized/types";

export const ModelType = "CART";

export default class Cart extends Model {
  // Attributes
  status: string = "";
  discountCode: string = "";
  discountAmount: number = 0;
  productOfferDiscountAmount: number = 0;
  subtotal: number = 0;
  totalTax: number = 0;
  total: number = 0;
  currency: string = "";
  creditsUsed: number = 0;
  giftCards: object[] = [];
  giftCardCodes: object[] = [];
  shippingAddress: object = {};
  creditRemaining: number = 0;
  giftCardAmountUsed: number = 0;
  productRecommendations: Array<string> = [];
  shippingAmount: number = 0;
  taxInclusive: boolean = false;

  // BelongsTo Relationships
  userId: string = "";
  guestId: string = "";
  couponId: string = "";
  promotionId: string = "";
  productPromotionId: string = "";
  shippingOptionId: string = "";

  // HasMany Relationships
  activePromotionIds: Array<string> = [];
  activeShippingOptionIds: Array<string> = [];

  constructor(attributes: { [key: string]: any }) {
    super();
    this.addAttributes(attributes);
  }

  get prediscountTotal(): number {
    return this.subtotal + this.discountAmount;
  }

  get preTaxTotal(): number {
    return this.total - this.totalTax;
  }

  deserialize(input: NormalizedModel) {
    this.id = input.id;

    this.addAttributes(input.attributes);

    const relationships = input.relationships;

    // BelongsTo
    this.userId = this.getRelationshipId(relationships, "user");
    this.guestId = this.getRelationshipId(relationships, "guest");
    this.couponId = this.getRelationshipId(relationships, "coupon");
    this.promotionId = this.getRelationshipId(relationships, "promotion");
    this.productPromotionId = this.getRelationshipId(
      relationships,
      "product_promotion",
    );
    this.shippingOptionId = this.getRelationshipId(
      relationships,
      "shipping_option",
    );

    // HasMany
    this.activePromotionIds = this.getRelationshipIds(
      relationships,
      "active_promotions",
    );
    this.activeShippingOptionIds = this.getRelationshipIds(
      relationships,
      "active_shipping_options",
    );
  }

  serialize(updatedAttributes: { [key: string]: any }) {
    const { discountCode, giftCardCodes, shippingAddress } = this;
    const attributes = {
      ...{
        discountCode,
        giftCardCodes,
        shippingAddress,
      },
      ...updatedAttributes,
    };
    return this.serializeInternal(attributes);
  }
}
